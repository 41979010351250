// 1. Import the utilities
import { extendTheme } from '@chakra-ui/react'

// 2. Update the breakpoints as key-value pairs
const breakpoints = {
  base: '0px',
  sm: '320px',
  lg: '768px'
}

// 3. Extend the theme
const custom_breakpoint_theme = extendTheme({ breakpoints })

export default custom_breakpoint_theme;
