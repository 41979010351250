import React from 'react';
import { OrderedList, UnorderedList, Box, Heading, Text, Link, ListItem, Container } from '@chakra-ui/react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'


const TermsOfUse = () => {

  return (
  <>

      <Container maxW="1280px">

        <Breadcrumb spacing='4px' padding="4" separator={<ChevronRightIcon color='blue.500' />}>
          <BreadcrumbItem color={'blue.500'}>
            <BreadcrumbLink href='Home'>Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color={'blue.500'}>
            <BreadcrumbLink href='#'>Terms</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Box display="flex" flexDirection="column" padding="4" alignItems="left" mb={8}>
          <Heading as="h1" size="md" mb={4}>Terms of Use</Heading>

          <Heading as="h2" size="sm" mt={2} mb={4} >Last Updated: September 21, 2024</Heading>

          <Text align={'justify'}>
            Please read these terms and conditions carefully before using Our Service.
          </Text>

          <Text align={'justify'}>
            These Terms of Use ("Terms") establish a legally binding agreement between you ("User") and Pabitra Kumar Padhy, doing business as Arogic("Arogic," "we," "us," or "our"). Arogic is a sole proprietorship that owns, operates, and maintains the website located at{" "}
            <Link href="https://arogic.com" color="blue.500" underline="solid" isExternal>
              https://arogic.com
            </Link>{" "}
            and the mobile application available at{" "}
            <Link href="https://app.arogic.com" color="blue.500" underline="solid" isExternal>
              https://app.arogic.com
            </Link>{" "}(collectively referred as the "Platform").
          </Text>


          <Heading as="h2" size="sm" mt={8}>DEFINITIONS</Heading>

          <Text align="justify">
            <Text fontWeight="bold" as="span">“Application”</Text><Text as="span"> means and includes Arogic’s web and mobile application, that can be accessed through any browser or installed/downloaded from the web.</Text>
          </Text>

          <Text align={'justify'}>
            <Text fontWeight="bold" as="span">“Customers”</Text><Text as="span">  For the purposes of these terms of use are registered users who
              subscribe for yoga therapy sessions and make required payment, shall be termed
              as Customers. </Text>
          </Text>

          <Text align={'justify'}>
            <Text fontWeight="bold" as="span">“Service”</Text><Text as="span"> means the Online Yoga Therapy Platform provided by Arogic which
              includes but is not limited to: connecting yoga therapists and users, enabling
              users to join live therapy sessions or take pre-recorded video sessions,
              enabling therapists to manage therapy schedule and user's exercise routine.</Text>
          </Text>

          <Text align={'justify'}>
            <Text fontWeight="bold" as="span">“Personal Data"</Text><Text as="span"> includes any personally identifiable data which the Customer
              shares in order to use the Service provided by the Platform/Website. This
              includes but is not limited to name, e-mail address, contact details, health
              information and usage data.</Text>
          </Text>

          <Heading as="h2" size="sm" mt={8}>SCOPE AND APPLICABILITY</Heading>
          <Text align="justify">These Terms of Use apply to you if:</Text>
          <OrderedList spacing={1}>
            <ListItem textAlign="justify">
              You register Yourself on the Platform.
            </ListItem>
            <ListItem textAlign="justify">
              Access the Platform and/or use the Services offered by such Platform.</ListItem>
            <ListItem textAlign="justify">
              You are above the age of 18 years.</ListItem>
          </OrderedList>
          <Text align="justify">
            Arogic grants to the Customer a limited, non-transferable, non-exclusive,
            revocable and limited right to access and use the Services being provided on
            the Platform owned and operated by Arogic.
          </Text>
          <Text align="justify">
            The Services may change from time to time at the sole discretion of Arogic. Any
            such change shall be updated and reflected in these Terms of Use. You agree to
            review these Terms of Use periodically and make yourself aware of any change in
            Services that may occur. You shall not hold Arogic responsible for any such
            change.
          </Text>
          <Text align="justify">
            Arogic reserves the right to revoke or terminate your access to the Services if
            you violate these Terms of Use, fail to pay any applicable fees, or engage in
            other prohibited activities.
          </Text>

          <Heading as="h2" size="sm" mt={8}>OUR SERVICES</Heading>

          <Text>
            Customers will have the following services available, which may be updated
            from time to time:
          </Text>

          <OrderedList spacing={1}>
            <ListItem textAlign="justify">
              Join the scheduled live therapy sessions from the application via a video conferencing application like Zoom.
            </ListItem>
            <ListItem textAlign="justify">
              Use pre-recorded video sessions, covering a variety of yoga practices that are curated by your therapist for your self-practice.
            </ListItem>
            <ListItem textAlign="justify">
              Learn about different Yoga practices through our text and video content, that are made available by the therapist.
            </ListItem>
          </OrderedList>

          <Heading as="h2" size="sm" mt={8}>CANCELLATIONS AND REFUNDS</Heading>
          <Text align="justify">
            Arogic offers a free 7-day trial for new users without any payment required. This
            allows you to experience the platform before making a commitment.
          </Text>
          <Text align="justify">
            Payments made after the trial period for any subscriptions are non-refundable.
            Please carefully consider your needs before finalizing your subscription.
          </Text>

          <Heading as="h2" size="sm" mt={8}>RESTRICTIONS ON USING THE SERVICES</Heading>
          <Text align="justify">
            The Customer while using the Services shall adhere to the following
            guidelines:
          </Text>
          <Text align="justify">The Customer shall not:</Text>
          <OrderedList spacing={1}>
            <ListItem textAlign="justify">
              Use the Services in such a manner that it violates the copyright, trademark, trade secrets, or other proprietary rights of the Company or any third-party.
            </ListItem>
            <ListItem textAlign="justify">
              Transmit viruses, malicious content or any other harmful content on the Platform.
            </ListItem>
            <ListItem textAlign="justify">
              Breach any applicable law while using the Services.</ListItem>
            <ListItem textAlign="justify">
              Reproduce, re-engineer, copy, re-publish, modify or tamper in any way the Platform.
            </ListItem>
            <ListItem textAlign="justify">
              Grant unauthorized access to any third-party.
            </ListItem>
            <ListItem textAlign="justify">
              Copy or re-use the Company’s logo, trademark or other Intellectual Property, either commercially or non-commercially, without prior written authorization by the Company.
            </ListItem>
            <ListItem textAlign="justify">
              Collect, track or disclose the personal information of others for any purpose other than as permitted by these Terms of Use and applicable data privacy laws.
            </ListItem>
            <ListItem textAlign="justify">
              Submit false or misleading information.
            </ListItem>
            <ListItem textAlign="justify">
              Misuse the Services or attempt to interfere with the proper functioning of the Platform in any manner not covered above.
            </ListItem>
            <ListItem textAlign="justify">
              Use the Services in an illegal, improper or offensive manner.
            </ListItem>
            <ListItem textAlign="justify">
              Use the Services or the Platform/Website in in a fraudulent manner.
            </ListItem>
          </OrderedList>
          <Text align="justify" fontStyle="italic">
            If the customer is found in violation of the usage of the services or the
            platform/website, the company at its sole discretion reserves the right to
            discontinue the services and prohibit the customer from using the
            platform/website and/ or the services.
          </Text>

          <Heading as="h2" size="sm" mt={8}>ACCOUNTS AND PASSWORDS</Heading>
          <Text align="justify">
            You would need to create an account with your Email & Passowrd or Login
            through Google, and register with the Platform before you can start using the
            Services. If you forget your password, you can reset it by following the
            instructions on the login page.
          </Text>
          <Text align="justify">
            You acknowledge and agree that the safety of Your account is important for the
            Platform to run smoothly and hence, You shall not share the account details or
            the password with any unauthorized person. If we detect any suspicious activity
            through Your account, we shall at our sole discretion, suspend or terminate
            Your account with immediate effect.
          </Text>
          <Text align="justify">
            You shall be solely and exclusively responsible for any activity in or through
            Your account. We shall not be responsible for any illegal, unlawful or improper
            activity being carried on through Your account.
          </Text>

          <Heading as="h2" size="sm" mt={8}>SUBSCRIPTIONS</Heading>
          <Text align="justify">
            Customers who are under the trial period or have purchased a Subscription
            will have access to the Platform. Subscription details, including pricing and
            program options, will be shared with you after registration based on your
            individual needs. You will be notified for renewal of the subscription when
            its due.
          </Text>
          <Heading as="h3" size="sm">Taxes</Heading>
          <Text align="justify">
            Any tax liability or compliance requirement that may arise from your usage of
            the Platform shall be Your sole and exclusive liability.
          </Text>
          <Heading as="h2" size="sm" mt={8}>PERSONAL DATA</Heading>
          <Text align="justify">
            You agree that in pursuance of your usage of Services on the Platform, you
            may be required to share certain Personal and Health Data. You give your free,
            fair and informed consent to the following:
          </Text>

          <OrderedList spacing={1}>
            <ListItem textAlign="justify">The data being collected by Arogic.</ListItem>
            <ListItem textAlign="justify">The purpose for which the data is being collected.</ListItem>
            <ListItem textAlign="justify">The entities which may process the data.</ListItem>
          </OrderedList>

          <Text align="justify">
            Please refer to our Privacy Policy for more information on data collection,
            usage, sharing, and security. Your access and usage of the Platform deems Your
            consent to the privacy policy.
          </Text>

          <Text align="justify">
            You further agree that Arogic shall not be responsible or liable for the
            authenticity or genuineness of the Personal Data which you may have provided.
            If any erroneous or misleading information has been provided by You, Arogic
            reserves the right, in its sole discretion, to immediately terminate your
            Service Account.
          </Text>

          <Heading as="h2" size="sm" mt={8}>INTELLECTUAL PROPERTY</Heading>
          <Text align="justify">
            You agree that any services offered, software used, content posted or made
            available or any other such functionalities which may be connected or
            associated with the Platform or the Services are the intellectual property of
            Arogic. This includes, but is not limited to, copyrights, trademarks, trade
            secrets, patents, and other proprietary rights. You agree to keep all
            information pertaining to these confidential at all times. You further agree
            that you shall not modify, re-sell, reuse, license out, distribute or use the
            intellectual property of Arogic in any unauthorized manner. Additionally, you
            are prohibited from reverse engineering, decompiling, or attempting to
            disassemble any software or other technology used in connection with the
            Platform or Services. Any use of the intellectual property of Arogic shall be
            made only upon an express written authorization and consent of Arogic. Any
            violation of this Clause shall be considered a breach of these Terms of Use
            and Arogic is free to pursue any legal remedy it deems fit for the enforcement
            and protection of its rights.
          </Text>

          <Heading as="h2" size="sm" mt={8}>INTERACTION WITH YOGA THERAPISTS</Heading>
          <Text align="justify">
            You acknowledge and agree that the Therapists offering their services on the
            Platform are not employees, agents, consultants of Arogic, but are independent
            professionals and entities and Arogic is not responsible or liable for their
            conducts or omissions.
          </Text>

          <Text align="justify">
            We act as an intermediary in connecting you with the Therapist (via email,
            phone, chats and/or video conferencing technologies). The Platform enables
            the therapist to connect with users and provide therapy sessions and is not
            involved in the way service is provided. In communicating with the therapist
            through the Platform, You consent to the following:
          </Text>

          <OrderedList spacing={1}>
            <ListItem textAlign="justify">
              Voluntary consent for the provision of all data you provide to the therapist.
            </ListItem>
            <ListItem textAlign="justify">
              Exhibit a polite conduct and not harass the therapist at improper times.
            </ListItem>
            <ListItem textAlign="justify">
              Not share unauthorized information about any third-party.
            </ListItem>
            <ListItem textAlign="justify">
              Not engage in any illegal or unlawful conduct.</ListItem>
            <ListItem textAlign="justify">
              Voluntary consent to share videos, images with the Platform and/or therapist.
            </ListItem>
          </OrderedList>

          <Heading as="h2" size="sm" mt={8}>TRADEMARKS</Heading>
          <Text align="justify">
            You agree that all the trademarks, logos and service marks displayed on the
            Platform are the property of Arogic. Any unauthorized use of the same shall be
            treated as infringement or passing off. Nothing on the Platform shall be
            interpreted as granting any form of authorization or license, express/implied,
            written/unwritten, to any individual to use any trademarks belonging to
            Arogic.
          </Text>

          <Heading as="h2" size="sm" mt={8}>COPYRIGHT NOTICE</Heading>
          <Text align="justify">
            Subject to any third-party licenses, all content published or displayed on the
            Platform and the materials downloaded from the Platform is either a) the sole
            copyright of Arogic or b) A shared copyright between Arogic and a third-party
            or c) An exclusive copyright of a third-party. You agree that any form of
            copying, distribution or using for any other purpose of such content without
            an express written permission from Arogic shall be treated as copyright
            infringement. Such infringement may result in legal action, including but not
            limited to, damages, injunctions, and attorneys' fees.
          </Text>

          <Heading as="h2" size="sm" mt={8}>THIRD PARTY SERVICE PROVIDERS</Heading>
          <Text align="justify">
            In order to provide Services in the best possible manner, We may use certain
            third-party service providers and/or third-party tools. You agree to the usage
            of third-party service providers for the provision of Services. As we depend
            upon third-party service providers, We cannot guarantee the uninterrupted and
            error-free running of the Platform at all times. You acknowledge and consent
            to this limitation and agree to not hold Arogic liable for the consequences
            that arise out of the conduct/services of the third-party service providers or
            third-party tools.
          </Text>

          <Heading as="h2" size="sm" mt={8}>THIRD PARTY LINKS</Heading>
          <Text align="justify">
            We may place third-party links on the Platform. Please note that such
            third-party links are not under the ownership, control, supervision or
            moderation of Arogic. Any objectionable content or information which you may
            access as a result of your clicking a third-party link shall not be the
            responsibility of Arogic and Arogic shall not be liable for the same. You agree
            that you shall access these third-party links completely, exclusively and
            solely at your own risk and shall not hold Arogic liable at any point of time
            for any harm suffered or any violation of a legal right which may be the result
            of accessing or using such links. Please note that you may be subject to the
            terms of use and privacy policies of these third-party service providers.
          </Text>

          <Heading as="h2" size="sm" mt={8}>INDEMNIFICATION</Heading>
          <Text align="justify">
            You agree to indemnify, defend, and hold harmless Arogic and any of its
            affiliates, contractors, professionals, and consultants ("The Indemnified
            Party") from and against any claims, damages, costs, liabilities, and expenses
            (including, but not limited to, legal expenses) arising out of your:
          </Text>

          <UnorderedList spacing={1}>
            <ListItem textAlign="justify">
              Breach of any applicable law
            </ListItem>
            <ListItem textAlign="justify">
              Posting of any unlawful or prohibited content
            </ListItem>
            <ListItem textAlign="justify">
              Illegal or prohibited use of the Platform
            </ListItem>
            <ListItem textAlign="justify">
              Breach of any intellectual property or confidentiality obligations
            </ListItem>
            <ListItem textAlign="justify">
              Any third-party claims brought against the Company, arising out of your use of the Platform.
            </ListItem>
          </UnorderedList>

          <Text align="justify">
            You agree that the Indemnified Parties can at any point of time, even after the
            expiration or termination of your Service Account with Arogic, claim indemnity
            from you for the aforementioned purposes. This Clause shall survive beyond
            the application of these Terms of Use for a period of 2 years.
          </Text>

          <Text align="justify">
            You also agree to immediately notify Arogic, in the event any third-party has
            initiated any legal proceeding against You, in connection with your use of the
            Platform.
          </Text>

          <Heading as="h2" size="sm" mt={8}>LIMITATION OF LIABILITY</Heading>
          <Text align="justify">
            You agree that Arogic shall not be liable for any direct, indirect,
            consequential, or remote damages in connection with these Terms of Use. Arogic
            shall also not be liable for any damages that may arise because of your misuse
            of the Platform or Services or your breach of the obligations of these Terms
            of Use.
          </Text>

          <Text align="justify">
            Arogic shall not be liable for any lost profits, lost data, personal injury, or
            other consequential or incidental damages arising out of or in connection with
            these Terms of Use.
          </Text>

          <Text align="justify">
            You acknowledge and agree that Arogic shall not be held liable for any
            damages, losses, or any other kind of liability arising out of or in connection
            with these Terms of Use.
          </Text>

          <Text align="justify">
            Please note that the foregoing limitation of liability shall apply regardless
            of whether Arogic has been advised of the possibility of such damages.
          </Text>

          <Heading as="h2" size="sm" mt={8}>DISCLAIMER OF WARRANTIES</Heading>
          <Text align="justify">
            You acknowledge and agree that you shall use the platform at your sole risk.
            All content and services which you access, and use are solely on an ‘as-is’ and
            ‘as-available’ basis. To the extent permitted by applicable law, Arogic
            expressly disclaims all express or implied warranties of any kind, including,
            but not limited to the implied warranties and conditions of merchantability,
            satisfactory quality, fitness for a particular purpose or use and
            non-infringement. Neither Arogic nor any of its licensors or affiliates makes
            any positive representations or warranties regarding the services offered.
            Arogic does not guarantee that the services offered will be error-free or
            uninterrupted. You agree that any material downloaded or otherwise obtained
            through the platform is done at your own discretion and solely at your own
            risk. You further agree that you will be solely responsible for any damage to
            your computer system or loss of data that results from the download of any
            such material. Arogic does not provide any warranty except those expressly
            stated in this agreement.
          </Text>

          <Heading as="h2" size="sm" mt={8}>NO MEDICAL ADVICE</Heading>
          <Text align="justify">
            The Platform and the Services offered therein, are mere facilitation services
            and Arogic shall not be held liable for any medical advice that the Customer
            obtains from its Therapist through the Platform. Therapists are independent
            professionals, and Arogic is not responsible for their conduct or omissions.
            Arogic do not represent or warrant that the Therapist You are connected to
            through the Platform shall always render correct advice or will be suited to
            You.
          </Text>

          <Text align="justify">
            Yoga therapy can be helpful for managing chronic conditions, but it's not a
            substitute for medical treatment. We suggest you to consult a healthcare
            professional before starting any new exercise program. You're responsible
            for your own health decisions.
          </Text>

          <Text align="justify">
            We hereby expressly disclaim all liability arising from Your interaction with
            therapists on the Platform.
          </Text>

          <Heading as="h2" size="sm" mt={8}>COMPLIANCE WITH APPLICABLE LAWS</Heading>
          <Text align="justify">
            You agree that Your usage of the Platform and the Service, including but not
            limited to the content posted and the information supplied shall always be in
            compliance with applicable laws, including data privacy laws, intellectual
            property laws, and consumer protection laws. Any breach on your part of any
            applicable law regarding any matter or provision of these Terms of Use shall
            be construed as a material breach. Arogic can then at its sole discretion
            initiate legal proceedings against You and automatically terminate your
            Account with immediate effect. You agree that any such breach of applicable
            law may cause Arogic loss of reputation and hence, You agree to reimburse
            Arogic in terms of monetary damages for any such loss.
          </Text>

          <Heading as="h2" size="sm" mt={8}>REPRESENTATIONS AND WARRANTIES</Heading>
          <Text align="justify">
            You represent and warrant that:
          </Text>
          <OrderedList spacing={1}>
            <ListItem textAlign="justify">
              You shall use the Platform only for lawful purposes.</ListItem>
            <ListItem textAlign="justify">
              You shall not upload or share any offensive, sexually explicit, political or
              illegal content through or on the Platform and shall at all times remain in
              compliance with all applicable laws.
            </ListItem>
            <ListItem textAlign="justify">
              You shall not infringe any copyrights, trademarks or other such
              intellectual property rights while using the Platform.
            </ListItem>
          </OrderedList>
          <Text align="justify">
            Any misrepresentation or falsehood in the representations and warranties stated
            herein shall entitle Arogic to terminate Your account and permanently bar Your
            access to the Platform. In addition to the same, Arogic may pursue any legal
            remedy it deems fit including but not limited to monetary damages.
          </Text>

          <Heading as="h2" size="sm" mt={8}>WAIVER</Heading>
          <Text align="justify">
            You agree that no provision of these Terms of Use shall be deemed to be waived
            by Arogic, until and unless Arogic has expressly in writing consented to such
            waiver. Any such waiver must be signed by an authorized representative of
            Arogic. Moreover, any such waiver by Arogic shall not be construed as consent
            for any subsequent waiver.
          </Text>

          <Heading as="h2" size="sm" mt={8}>SEVERABILITY</Heading>
          <Text align="justify">
            If any provision of these Terms of Use becomes invalid or unenforceable due to
            any reason, such invalidity or unenforceability shall not lead to the entire
            Terms of Use becoming unenforceable. The unenforceable or invalid portion shall
            be deemed to be severed from the Terms of Use without affecting the validity of
            the entire Terms of Use, including individual sections or subsections.
          </Text>

          <Heading as="h2" size="sm" mt={8}>FORCE MAJEURE</Heading>
          <Text align="justify">
            You agree that You shall not hold Arogic responsible or liable for any
            non-performance of any obligations under these Terms of Use including but not
            limited to the Services being offered, if such non-performance arises out of
            or in connection with the following events:
          </Text>
          <OrderedList spacing={1}>
            <ListItem textAlign="justify">Floods, fire earthquakes or any natural disaster or acts of god.</ListItem>
            <ListItem textAlign="justify">Riots, war or any such act of violence.</ListItem>
            <ListItem textAlign="justify">Epidemics/Pandemics</ListItem>
            <ListItem textAlign="justify">
              Unprecedented technical errors including but not limited to network errors,
              unforeseen down time of the internet and power outages affecting internet
              services.
            </ListItem>
            <ListItem textAlign="justify">Any change in applicable laws, Government actions or regulations</ListItem>
            <ListItem textAlign="justify">Any other reason beyond the Arogic's control.</ListItem>
          </OrderedList>
          <Text align="justify">
            You further agree that You shall not hold Arogic liable for any interruption
            arising out of the aforementioned events. Arogic can its sole discretion,
            terminate the Terms of Use automatically and with immediate effect either
            temporarily or permanently, if such events continue beyond a certain period of
            time.
          </Text>

          <Heading as="h2" size="sm" mt={8}>GOVERNING LAW AND JURISDICTION</Heading>
          <Text align="justify">
            All matters, claims and any other such issues which may directly or indirectly
            arise from or in connection with this Terms of Use shall be governed under the
            laws of India irrespective of its conflict of laws rules applicable to
            contracts to be performed within India. The High Courts of Telangana and the
            District Courts of Hyderabad shall have the sole and exclusive jurisdiction to
            hear all matters, claims or any other such issues which may directly or indirectly
            arise from or in connection with these Terms of Use. You consent to the
            exclusive jurisdiction of the High Court of Telangana and the District Courts
            of Hyderabad in connection with any matters arising from or related to these
            Terms of Use.
          </Text>

          <Heading as="h2" size="sm" mt={8}>CHANGES TO THE TERMS OF USE</Heading>
          <Text align="justify">
            Arogic may, at its sole and exclusive discretion, revise, modify or amend the
            information contained in these terms of use at any point of time. Any such
            revision, modification or amendment shall be effective immediately upon either
            posting or otherwise notifying you via email, in-app notification or prominent
            notice on the Platform. You agree to keep yourself aware of any such changes.
          </Text>

          <Heading as="h2" size="sm" mt={8}>QUESTIONS AND CONCERNS</Heading>
          <Text align="justify" as="span">
            If you have any queries or concerns regarding these Terms of Use, you may contact us at:
            <Link isExternal href="mailto:support@arogic.com" color="blue.500"> support@arogic.com</Link>. We strive to respond to all inquiries within 5 business days and will do our best to address your concerns.
          </Text>
        
        </Box>
      </Container>
    </>
  );
};

export default TermsOfUse;