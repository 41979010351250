import React from 'react';
import { OrderedList, Box, Heading, Text, Link, ListItem, Container } from '@chakra-ui/react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'


const PrivacyPolicy = () => {

  return (
  <>

    <Container maxW="1280px">

        <Breadcrumb spacing='4px' padding="4"separator={<ChevronRightIcon color='blue.500' />}>
          <BreadcrumbItem color={'blue.500'}>
            <BreadcrumbLink href='Home'>Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color={'blue.500'}>
            <BreadcrumbLink href='#'>Policy</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

      <Box display="flex" flexDirection="column" padding="4" alignItems="left" mb={8}>
        <Heading as="h1" size="md" mb={4}> Privacy Policy </Heading>

        <Heading as="h2" size="sm" mt = {2} mb={4} >Last Updated: September 21, 2024</Heading>

        <Text align="justify">
          This Privacy Policy explains how Arogic ("we," "us," or "our") collects, uses, and discloses information when you access or use our Platform,
          including our website available at <Link href="https://arogic.com" color="blue.500" underline="solid" isExternal> https://arogic.com </Link>, user mobile application
          available at <Link href="https://app.arogic.com" color="blue.500" underline="solid" isExternal> https://app.arogic.com </Link>
          , therapist tool available at <Link href="https://coach.arogic.com" color="blue.500" underline="solid" isExternal> https://coach.arogic.com </Link>{" "}
          and any other channels that collect user data (collectively, the "Platform"). This privacy policy applies to both Users and Therapists using the Platform.
        </Text>

        <Text mt={2} align="justify">
          Your privacy is important to us. This Privacy Policy is designed to help
          you understand what information we collect, how we use it, and what
          choices you have
          about your information.
        </Text>

        {/* Agreement */}
        <Text mt={2} align="justify">
          By accessing or using the Platform, you agree to this Privacy Policy. If
          you do not agree to this Privacy Policy, please do not use our Platform.
        </Text>

        {/* Information Collection */}
        <Heading as="h2" size="sm" mt={8}>
          What Personal Information May Be Collected?
        </Heading>

        <Text>
          While providing services, Arogic may have to collect, store, and retain
          certain Personal Information from you. This information may include but
          not limited to:
        </Text>

        {/* Personal Details */}
        <OrderedList spacing={1} mt={4} ml={4}>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Personal Details: </Text>
              <Text as="span">Name, gender, height, weight, and age.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Geographical Information: </Text>
              <Text as="span">City, State, and other location details.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Contact Information: </Text>
              <Text as="span">Email address and telephone number.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Health Information: </Text>
              <Text as="span">Ailments, medical history, and any relevant information you choose to provide.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Health Records/Data: </Text>
              <Text as="span">Test reports, x-ray reports, prescriptions and progress images of the body parts/areas being treated.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Audio, Video, and Images: </Text>
              <Text as="span">Photograph, Audio and Video recording of therapy sessions, including both self-sessions and sessions with therapists.</Text>
            </Text>
          </ListItem>

          <Text ml={0} mt={1}>For Therapists, we may additionally collect: </Text>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Professional Information: </Text>
              <Text as="span">Qualifications, experience, payment information and details for video conferencing.</Text>
            </Text>
          </ListItem>
        </OrderedList>

        <Heading as="h2" size="sm" mt={8}>
          How Do We Collect Information?
        </Heading>

        <OrderedList spacing={1} mt={4}>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span" align="justify">Information provided by You: </Text>
              <Text as="span" align="justify">We may ask for Information, as outlined above, at the time of Your registration on the Platform and while using the Platform. In addition to the above, We may also collect information from You, through marketing and promotional materials including but not limited to, newsletters, marketing tools and campaigns. You willingly consent to provide the Company your Personal Information at the time of registration on the Platform.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span" align="justify">Information collected automatically: </Text>
              <Text as="span" align="justify">Some data such as your browser type, internet protocol address used, your usage patterns may be automatically collected by the Company using cookies and tracking pixels. Cookies and tracking pixels are tiny pieces of information which a website sends to Your computer or device. We use these to provide you with a more personalized experience on the Platform. By continuing to use the Platform, you consent to the use of cookies and tracking pixels. You can change your browser settings to manage your cookie preferences.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span" align="justify">Information we may receive from third parties: </Text>
              <Text as="span" align="justify">We may receive information about you from third parties. For example, if you access our Platform or services through a third-party connection or log-in, for example, through your Google account, or using Your email address, that third party may pass certain information about your use of its service to the Company. This information could include, but is not limited to, the user ID associated with your account (for example, your Google ID), an access token necessary to access that service, any information that you have permitted the third party to share with us, and any information you have made public in connection with that service. When you use our Platform, third parties might give us some information about you, especially if you are signed into a third party account while using our Platform.</Text>
            </Text>

          </ListItem>
        </OrderedList>

        <Heading as="h2" size="sm" mt={8}>
          How Do We Use the Data Collected?
        </Heading>

        <Text>
          We may use your personal information for the following purposes:
        </Text>

        <OrderedList spacing={1} mt={4} ml={4}>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Verifying your identity: </Text>
              <Text as="span">The contact details collected may be used to verify your identity.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Providing services: </Text>
              <Text as="span">We use your information to facilitate your use of the Platform and connect you with therapists.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Personalizing your experience: </Text>
              <Text as="span">We may use your data to personalize your experience on the Platform, such as recommending relevant content.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Improving the Platform: </Text>
              <Text>We analyze user data to improve the usability, features, and functionality of the Platform.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Developing new products and services:</Text>
              <Text as="span">We may use your data to develop new products, features, or services.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Marketing and promotions:</Text>
              <Text as="span">We may use your contact information to send you marketing and promotional materials about Arogic's products and services.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Compliance with legal obligations:</Text>
              <Text as="span">We may use your data to comply with legal and regulatory requirements.</Text>
            </Text>
          </ListItem>
          <Text mt={1}>For Therapists, we may also use your information to:</Text>
          <ListItem>
            <Text>Verify your credentials and qualifications.</Text>
          </ListItem>
          <ListItem>
            <Text>Process payments.</Text>
          </ListItem>
          <ListItem>
            <Text>Manage your schedule and appointments.</Text>
          </ListItem>
        </OrderedList>

        <Text mt={4} align="justify">
          You agree that the aforementioned purposes are valid and give Your informed consent to the collection of Your Personal Information for such purposes.
        </Text>

        <Heading as="h2" size="sm" mt={8}>
          How Long Will Personal Information Be Retained?
        </Heading>

        <Text align="justify">
          Subject to applicable laws, any information that is provided by you may be retained by Arogic, at its sole discretion for as long as required for commercial purposes. You may request the deletion of your personal data at any time by contacting us at <Link isExternal href="mailto:support@arogic.com" color="blue.500"> support@arogic.com</Link>. We will process your request in accordance with applicable data protection laws.
        </Text>

        <Heading as="h2" size="sm" mt={8}>
          Whom Does Arogic Share Your Personal Information With?
        </Heading>

        <Text>
          We may share Your Personal Information with:
        </Text>

        <OrderedList spacing={1} mt={4} ml={4}>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Employees and contractors of Arogic </Text>
              <Text as="span">who need access to your information to provide Services or support the Platform.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Therapists registered on the Platform </Text>
              <Text as="span">to facilitate your therapy sessions and provide the Services.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Third-party service providers </Text>
              <Text as="span">who assist us in operating the Platform and providing the Services. These third parties are bound by confidentiality agreements and are prohibited from using your data for any other purpose.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Government agencies, law enforcement, or regulatory bodies </Text>
              <Text as="span">if required by law or to comply with a legal process.</Text>
            </Text>
          </ListItem>
        </OrderedList>

        <Text align="justify">
          You agree that Arogic is not responsible for the unauthorized disclosure or misuse of your Personal Information by any third party.
        </Text>

        <Heading as="h2" size="sm" mt={8}>
          Your Rights
        </Heading>

        <Text align="justify">
          You shall have the following rights regarding your personal information under this Privacy Policy:
        </Text>

        <OrderedList spacing={1} mt={4} ml={4}>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Access: </Text>
              <Text as="span">You have the right to request access to your personal information that Arogic holds.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Correction: </Text>
              <Text as="span">You have the right to request that we correct inaccurate or incomplete personal information.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify" as="span">
              <Text fontWeight="bold" as="span">Deletion: </Text>
              <Text>You have the right to request deletion of your personal information.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Restriction: </Text>
              <Text as="span">You have the right to restrict or object to the processing of your personal information.</Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text align="justify">
              <Text fontWeight="bold" as="span">Withdraw Consent: </Text>
              <Text as="span">If we process your personal data based on your consent, you have the right to withdraw your consent at any time.</Text>
            </Text>
          </ListItem>
        </OrderedList>

        <Text mt={4} align="justify">
          To exercise any of these rights, please contact us at <Link isExternal href="mailto:support@arogic.com" color="blue.500"> support@arogic.com</Link>. We will respond to your request within a reasonable timeframe, typically within one month.
          Please note that exercising these rights may impact your ability to use the Platform or Services.
        </Text>

        <Heading as="h2" size="sm" mt={8}>
          Third-Party Websites
        </Heading>

        <Text align="justify">
          Some content on the Platform may contain links to third-party websites or platforms. If you choose to click on such links, you will be redirected to a third-party website. Please note that our Terms of Use and Privacy Policy do not apply to third-party websites. You are advised to review the terms of use and privacy policy of any third-party website before submitting any personal information on such a website.
        </Text>

        <Text align="justify">
          We provide links to third-party websites for informational purposes only. Arogic is not responsible for the content, practices, or policies of any third-party website.
        </Text>

        <Heading as="h2" size="sm" mt={8}>
          Security of Your Personal Information
        </Heading>

        <Text align="justify">
          We prioritize the security of your personal information and have implemented measures to protect it from unauthorized access. However, no method of online transmission is completely secure. Please use strong, unique passwords and avoid sharing them with others. We're not responsible for unauthorized access due to compromised passwords or any data breaches that occur due to events beyond our reasonable control, such as hacking, viruses, or other cyberattacks.
        </Text>

        <Heading as="h2" size="sm" mt={8}>
          Changes to Privacy Policy
        </Heading>

        <Text align="justify">
          There may be changes to this Privacy Policy from time to time. The last updated date shall be highlighted at the top of policy information. You shall be solely liable to review the Privacy Policy for any changes. Arogic is not obliged to intimate You regarding the changes.
        </Text>

        <Heading as="h2" size="sm" mt={8}>
          Force Majeure
        </Heading>

        <Text align="justify">
          You agree that You shall not hold Arogic responsible or liable for any non-performance of any obligations under this Privacy Policy, if such non-performance arises due to events including but not limited to:
        </Text>

        <OrderedList spacing={1} mt={4} ml={4}>
          <ListItem>
            <Text align="justify">Floods, fire earthquakes or any natural disaster or acts of god.</Text>
          </ListItem>
          <ListItem>
            <Text align="justify">Riots, war or any such act of violence.</Text>
          </ListItem>
          <ListItem>
            <Text align="justify">Epidemics/Pandemics.</Text>
          </ListItem>
          <ListItem>
            <Text align="justify">Unprecedented technical errors including but not limited to network errors, unforeseen down time of the internet and power outages affecting internet services.</Text>
          </ListItem>
          <ListItem>
            <Text align="justify">Hacking.</Text>
          </ListItem>
          <ListItem>
            <Text align="justify">Global internet shutdowns.</Text>
          </ListItem>
          <ListItem>
            <Text align="justify">Government actions or regulations.</Text>
          </ListItem>
          <ListItem>
            <Text align="justify">Any other reason beyond our control.</Text>
          </ListItem>
        </OrderedList>

        <Heading as="h2" size="sm" mt={8}>
          Limitation of Liability
        </Heading>

        <Text align="justify">
          You further agree that You shall not hold Arogic liable for any unauthorized disclosure or leakage of your Personal Information, directly or indirectly, by Arogic or any third-party. Arogic shall not be liable for any indirect, consequential, or remote damages, including but not limited to lost profits, lost data, or personal injury, arising out of or in connection with this privacy policy. The total liability of the Company in the event of a breach of this privacy policy shall not exceed INR 1000.
        </Text>

        <Text mt={4} align="justify">
          Please note that this Limitation of Liability shall apply regardless of whether Arogic has been advised of the possibility of such damages.
        </Text>

        <Heading as="h2" size="sm" mt={8}>
          Governing Law and Jurisdiction
        </Heading>

        <Text align="justify">
          All disputes or legal matters arising from or related to this Privacy Policy shall be governed by the laws of Hyderabad, India. The High Court of Telangana and the District Courts of Hyderabad shall have the sole and exclusive authority to hear and resolve any legal disputes arising from or related to this Privacy Policy. You agree not to file any legal action in any other court. You consent to the exclusive jurisdiction of the High Court of Telangana and the District Courts of Hyderabad in connection with any matters arising from or related to this Privacy Policy.
        </Text>

        <Heading as="h2" size="sm" mt={8}>
          Redressal of Grievances
        </Heading>

        <Text align="justify">
          If you have any questions or concerns regarding Our Platform, the content thereof, our data handling policies, or about our Services, you can reach out at
          <Link isExternal href="mailto:support@arogic.com" color="blue.500"> support@arogic.com </Link>
        </Text>
      </Box>
    </Container>
  </>
  );
};

export default PrivacyPolicy;
