import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import TermsOfUse from './pages/TermsOfUse.js';


function App() {
  return (
    <Router forceRefresh={true}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Home" element={<HomePage />}/>
        <Route path="/Policy" element={<PrivacyPolicy />} />
        <Route path="/Terms" element={<TermsOfUse />} />
        {/* Other routes */}
      </Routes>
    </Router>
  );
}

export default App;
