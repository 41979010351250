import { Box, Heading, VStack } from '@chakra-ui/react';
import LoginBackground from '../assets/LoginBackground.jpg';



const HomePage = () => {

 return (
    <Box
      backgroundImage={LoginBackground}
      backgroundSize="cover"
      backgroundPosition="center"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {/* Your content here */}
      <VStack spacing={4}>
        <Heading as='h1' fontSize='2xl' color='white'>Welcome to Arogic</Heading>
        <Heading as='h2' fontSize='3xl' color='white'>Website Coming Soon ...</Heading>
      </VStack>

    </Box>

  );
};

export default HomePage;
